<template>
    <div class="">
        <ATable :url="tableUrl" :columns="columns" :searchFormItems="searchFormItems" @operate-button-call-back="OperateButtonCallBack" ref="table">
            <el-button type="primary" @click="Editor()">新增</el-button>
        </ATable>
        <CardEditEl @submit="CardEditSubmit"/>
    </div>
</template>


<script>
import ATable from '@/components/table/index'
import CardEditEl from './module/cardEdit.vue'
import { inject, provide, ref } from 'vue'
import { list, CardDel } from '@/api/card'


export default {
    components: {ATable, CardEditEl},
    setup () {
        const tableUrl = ref(list)
        const elplus = inject('elplus')
        const columns = ref([
            {prop: 'name', label: '卡片名称'},
            {prop: 'icon', label: '图标', type: 'image'},
            {prop: 'content', label: '详情'},
            {prop: 'type', label: '类型', render: text =>{
                let label = {1: '明信片', 2: '知识卡'}
                return label[text] || '无'
            }},
            {prop: 'attribute', label: '属性', render: text => {
                let label = {1: '普通卡片', 2: '稀有卡片'}
                return label[text] || '无'
            }},
            {prop: 'shortIcon', label: '缩略图', type: 'image'},
            {prop: 'operate', label: '操作', type: 'operate', width: 140, button: [
                {label: '编辑', dispose: 'Editor'},
                {label: '删除', dispose: 'TaskDelete'}
            ]},
        ])
        
        const searchFormItems = ref([
            {label: '卡片名称', prop: 'name', type: 'input'},
            {label: '描述', prop: 'content', type: 'input'},
            {label: '类型', prop: 'type', type: 'select', options: [{label: '明信片', value: 1}, {label: '知识卡', value: 2}]},
            {label: '稀有度', prop: 'attribute', type: 'select', options: [{label: '普通卡片', value: 1}, {label: '稀有卡片', value: 2}]},
        ])
        let editorForm = ref({})
        let cardVisible = ref(false)
        let table = ref(null)
        provide('cardVisible', cardVisible)
        provide('editorForm', editorForm)

        const methods = {
            Editor (row = {}) {
                if (row.id) {
                    editorForm.value = {
                        name: row.name, 
                        icon: row.icon, 
                        shortIcon: row.shortIcon,
                        content: row.content, 
                        type: row.type || '', 
                        attribute: row.attribute || '', 
                        id: row.id
                    }
                } else {
                    editorForm.value = {name: '', icon: '', content: '', type: 1, attribute: 1, shortIcon: ''}
                }
                cardVisible.value = !cardVisible.value
            },
            OperateButtonCallBack ({row, fun}) {
                methods[fun](row)
            },
            TaskDelete (row) {
                elplus.confirm(`确定删除该任务吗？此操作不可逆！！！`).then(() => {
                    let loading = elplus.loading()
                    CardDel({id: row.id}).then(res => {
                        loading.close()
                        if (!res.ret) methods.CardEditSubmit()
                    })
                })
            },
            CardEditSubmit () {
                cardVisible.value = false
                table.value.SearchSubmit()
            }
        }

        return {
            table,
            tableUrl,
            columns,
            searchFormItems,
            ...methods
        }
    }
}
</script>

<style lang="scss" scoped>

</style>